<template>
	<v-sheet class="location" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md6>
					<p class="my-2 font-level-3-bold">
						<ShowValue :object="location" object-key="name" label="location name"></ShowValue>
						<v-chip class="ml-2" small color="white--text green" label v-if="location.activated"
							>Active</v-chip
						>
						<v-chip class="ml-2" small color="white--text red lighten-1" label v-else>De-Active</v-chip>
					</p>
					<v-btn v-on:click="doAction('edit')" color="blue darken-4" text depressed tile
						><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
					>
					<v-btn v-on:click="doAction('duplicate')" color="blue darken-4" text depressed tile
						><v-icon small left>mdi-content-duplicate</v-icon>Clone</v-btn
					>
					<v-btn v-on:click="doAction('delete')" color="blue darken-4" text depressed tile
						><v-icon small left>mdi-delete</v-icon>Delete</v-btn
					>
					<v-btn
						v-if="location.activated"
						v-on:click="doAction('deactivate')"
						color="blue darken-4"
						text
						depressed
						tile
						><v-icon small left>mdi-cancel</v-icon>De-activate</v-btn
					>
					<v-btn v-else v-on:click="doAction('activate')" color="blue darken-4" text depressed tile
						><v-icon small left>mdi-check-all</v-icon>Activate</v-btn
					>
				</v-flex>
				<v-flex md6 class="text-right">
					<v-btn
						v-on:click="doAction('print-label')"
						color="blue darken-4 text-white"
						class="mx-2"
						depressed
						tile
						><v-icon small left>mdi-printer</v-icon>Print Label</v-btn
					>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="locationTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon> Details</v-tab
					>
					<v-tab href="#asset"> <v-icon small left>mdi-hammer-wrench</v-icon> Assets</v-tab>
					<v-tab href="#asset-stock"> <v-icon small left>mdi-calendar-check</v-icon> Asset Stocks</v-tab>
					<v-tab href="#inventory"> <v-icon small left>mdi-history</v-icon> Inventories</v-tab>
					<v-tab href="#work-order"> <v-icon small left>mdi-clipboard-list</v-icon> Work Orders</v-tab>
					<v-tab href="#file"> <v-icon small left>mdi-file-document-multiple</v-icon> Files</v-tab>
					<v-tab href="#comment"> <v-icon small left>mdi-comment-multiple</v-icon> Comments</v-tab>
					<v-tab href="#support"> <v-icon small left>mdi-page-next-outline</v-icon> Support</v-tab>
				</v-tabs>

				<v-tabs-items v-model="locationTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/location-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Location Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th width="300px">Location #</th>
										<td>
											<ShowValue :object="location" object-key="barcode" label="location #"></ShowValue>
										</td>
										<th width="300px">Identification Number</th>
										<td>
											<ShowValue
												:object="location"
												object-key="id_number"
												label="identification number"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Total Asset</th>
										<td>
											<ShowValue
												:object="location"
												object-key="total_asset_formatted"
												label="total asset"
											></ShowValue>
										</td>
										<th width="300px">
											Total Asset Stock
											<TooltipQuestion>
												<template v-slot:text
													>Aggregated quantity of Asset Stock<br />with this location set as default.</template
												>
											</TooltipQuestion>
										</th>
										<td>
											<ShowValue
												:object="location"
												object-key="total_asset_stock_formatted"
												label="total asset stock"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">
											Total Perishables
											<TooltipQuestion>
												<template v-slot:text
													>Aggregated quantity of Perishables items<br />with this location set as
													default.</template
												>
											</TooltipQuestion>
										</th>
										<td colspan="3">
											<ShowValue
												:object="location"
												object-key="total_inventory"
												label="total inventory"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Parent Location(s)</th>
										<td colspan="3">
											<ShowValue
												:object="location"
												object-key="parent_location"
												label="parent location(s)"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Address</th>
										<td colspan="3">
											<ShowValue :object="location" object-key="address" label="address"></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="300px">Description</th>
										<td colspan="3">
											<ShowValue :object="location" object-key="description" label="description"></ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="asset">
						<AssetListing
							v-if="locationTab == 'asset'"
							class="mx-4"
							relation-type="location"
							:relation-type-id="location.id"
						></AssetListing>
					</v-tab-item>
					<v-tab-item value="asset-stock">
						<AssetStockListing
							v-if="locationTab == 'asset-stock'"
							class="mx-4"
							relation-type="location"
							:relation-type-id="location.id"
						></AssetStockListing>
					</v-tab-item>
					<v-tab-item value="inventory">
						<InventoryListing
							v-if="locationTab == 'inventory'"
							class="mx-4"
							relation-type="location"
							:relation-type-id="location.id"
						></InventoryListing>
					</v-tab-item>
					<v-tab-item value="work-order">
						<WorkOrderListing
							v-if="locationTab == 'work-order'"
							class="mx-4"
							type-text="Location"
							type="location"
							:type-uuid="uuid"
						></WorkOrderListing>
					</v-tab-item>
					<v-tab-item value="file">
						<Files
							v-if="locationTab == 'file'"
							class="mx-4"
							type-text="Location Files"
							type="location"
							:type-uuid="uuid"
						></Files>
					</v-tab-item>
					<v-tab-item value="comment">
						<Comments
							v-if="locationTab == 'comment'"
							class="mx-4"
							type-text="Location"
							type="location"
							:type-uuid="uuid"
						></Comments>
					</v-tab-item>
					<v-tab-item value="support">
						<SupportTemplate
							v-if="locationTab == 'support'"
							class="mx-4"
							type-text="Location"
							type="location"
							:type-uuid="uuid"
						></SupportTemplate>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<DeleteTemplate
				type="Location"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					location.activated
						? 'To preserve associated records, you could de-activate the Location instead.'
						: null
				"
			>
				<template v-if="location.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Location"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Location"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Comments from "@/view/components/Comments";
import Files from "@/view/components/Files";
import SupportTemplate from "@/view/components/SupportTemplate";
import WorkOrderListing from "@/view/components/WorkOrderListing";
import { GetLocation } from "@/core/lib/location.lib";
import ShowValue from "@/view/components/ShowValue";
import AssetListing from "@/view/components/AssetListing";
import AssetStockListing from "@/view/components/AssetStockListing";
import InventoryListing from "@/view/components/InventoryListing";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";

export default {
	name: "location-detail",
	title: "Detail Location",
	data() {
		return {
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			deleteURL: null,
			deleteDialog: false,
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
			locationTab: null,
			location: {},
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "location-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "duplicate":
					this.$router.push({
						name: "location-create",
						query: { duplicate: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		getLocation() {
			GetLocation(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.location = data;
					this.deleteText = `#${data.barcode} - ${data.name}`;
					this.deleteURL = `location/${data.uuid}`;
					this.activateText = `#${data.barcode} - ${data.name}`;
					this.activateURL = `location/${data.uuid}/activate`;
					this.deactivateText = `#${data.barcode} - ${data.name}`;
					this.deactivateURL = `location/${data.uuid}/de-activate`;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Location", disabled: true },
						{ text: "Detail", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
	},
	components: {
		Files,
		Comments,
		ShowValue,
		AssetListing,
		AssetStockListing,
		DeleteTemplate,
		ActivateTemplate,
		DeactivateTemplate,
		InventoryListing,
		TooltipQuestion,
		SupportTemplate,
		WorkOrderListing,
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Location", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getLocation();
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
